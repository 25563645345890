import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Formik, Field, Form, ErrorMessage, useField} from 'formik';
import * as Yup from "yup";
import * as emailjs from "emailjs-com";
// import emailJS from "../emailkey";

let emailJSObj = {};

const MyTextInput = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            {/*{hasFocus ? " 👇" : null}*/}
            <input id={props.id} tabIndex={props.tabIndex}
                   className={`text-input ${(meta.touched && meta.error) ? "error-border" : ""}`}
                   {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

const MyTextarea = ({label, ...props}) => {
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <textarea id={props.id} tabIndex={props.tabIndex} rows="5"
                      className={`text-input ${(meta.touched && meta.error) ? "error-border" : ""}`}
                      {...field} {...props} ></textarea>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

function SendEmail(object, resetForm, setSubmitting) {
    emailjs.send(emailJSObj.serviceId, emailJSObj.templateId, object, emailJSObj.userId)
        .then((result) => {
                alert("Message Sent, We will get back to you shortly", result.text);
                resetForm();
            },
            (error) => {
                alert("An error occurred, Please try again", error.text);
                // alert(JSON.stringify(object, null, 2));
                // TODO Maybe send email to admin
            })
        .finally(() => {
            setSubmitting(false);
        });
}

const ContactForm = ({emailJS}) => {
    emailJSObj = emailJS;
    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            }}
            // validate,
            validationSchema={Yup.object({
                    firstName: Yup.string()
                        .max(15, 'Must be 15 characters or less')
                        .required('Required'),
                    lastName: Yup.string()
                        .max(20, 'Must be 20 characters or less')
                        .required('Required'),
                    email: Yup.string().email('Invalid email address').required('Required'),
                    message: Yup.string()
                        .max(300, 'Must be 300 characters or less')
                        .required('Required'),
                }
            )}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    SendEmail(values, resetForm, setSubmitting);
                }, 400);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  setSubmitting,
                  isSubmitting,
                  dirty,
                  isValid,
                  values
              }) => (
                <Form>
                    <MyTextInput
                        label="First Name"
                        id="first-name"
                        name="firstName"
                        type="text"
                        placeholder="Jane"
                        tabIndex="0"
                    />

                    <MyTextInput
                        label="Last Name"
                        id="last-name"
                        name="lastName"
                        type="text"
                        placeholder="Doe"
                        tabIndex="0"
                    />

                    <MyTextInput
                        label="Email Address"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="jane@formik.com"
                        tabIndex="0"
                    />

                    <MyTextarea
                        label="Message"
                        id="message"
                        name="message"
                        type="text"
                        placeholder="Your message"
                        tabIndex="0"
                    />

                    <button type="submit" disabled={!(isValid && dirty) || isSubmitting}>Submit</button>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;