import {Col, Container, Row} from "reactstrap";

function Bio() {
    const author = {
        "name": "Kostis Tsafaris",
        "desc": "Hello! My name is Konstantinos Tsafaris (Kostis) and I am a Web Developer. What drives me forward, is my passion for coding and new technologies. Feel free to navigate through my website and don't forget to visit my social media pages. If you want to ask a question or just say 'Hi!', use the contact form - I am always happy to respond!",
        "avatar": "assets/img/face-outline.png"
    };
    return (
        // fluid="md"
        <Container fluid className="background-main2 d-flex">
            <div className="justify-content-center align-self-center bg-semi-transparent w-100 p-5">
                <h2 className="text-center mb-4">Short Bio</h2>
                <Row>
                    <Col/>
                    <Col md={8}><p className="text-justify lead">{author.desc}</p></Col>
                    <Col/>
                </Row>
            </div>
        </Container>
    );
}

export default Bio;