import React, {Component, useState} from 'react';
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Button} from 'reactstrap';
import {NavLink as RRNavLink} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Navigation(props) {
    const menuItems = [
        {
            "name": "Home",
            "url": "home"
        },
        {
            "name": "Bio",
            "url": "bio"
        },
        {
            "name": "Skills",
            "url": "skills"
        },
        {
            "name": "Projects",
            "url": "projects"
        },
        {
            "name": "Contact",
            "url": "contact"
        }
    ]

    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);
    const toggleMobileNavbar = () => {
        if (!collapsed)
            setCollapsed(true);
    };

    const navigationItems = menuItems.map((item) => {
        return (
            <NavItem key={item.name}>
                <NavLink tag={RRNavLink} to={item.url} activeClassName="active"
                         onClick={toggleMobileNavbar}>{item.name}</NavLink>
            </NavItem>
        );
    });
    return (
        <div>
            {/*fixed="top"*/}
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/" className="mr-auto">
                    <span>Kostis Tsafaris - Web Developer</span>
                    <FontAwesomeIcon icon="code" className="ml-2"/>
                    <span className="mx-auto">{}</span>
                </NavbarBrand>
                <div className="w-100 text-md-center mb-2 mb-md-0">
                    <a href={'https://tsafaris.com'} style={{color:'red'}}>AngularJS version</a>
                </div>
                <NavbarToggler onClick={toggleNavbar} className="mr-2"/>
                <Collapse isOpen={!collapsed} navbar>
                    <Nav className="ml-auto" navbar>
                        {navigationItems}
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );

}

export default Navigation;