import {Col, Container, Row} from "reactstrap";
import {Image} from "react-native";
import {Link} from 'react-router-dom';

function Bio() {
    const projects = [
        {
            name: 'ioannakostiswedding',
            url: 'http://www.ioannakostiswedding.com'
        },
        {
            name: 'thefleamarketskg',
            url: 'http://www.thefleamarketskg.com'
        },
        {
            name: 'thessalonikistreetfoodfestival',
            url: 'http://www.thessalonikistreetfoodfestival.com'
        }
    ];
    const projectsItems = projects.map((project) => {
        return (
            <Col key={project.name} className="text-center mb-2 mb-md-4" xs={12} md={6} lg={4}>
                <a href={project.url} target="_blank">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/otherProjects/${project.name}.jpg`}
                         alt={project.name} width="100%"/>
                </a>
            </Col>
        )
    });

    return (
        <Container fluid className="background-main5 d-flex">
            <div className="justify-content-center align-self-center bg-semi-transparent w-100 p-md-5 p-0">
                <h2 className="text-center p-2 p-md-5 text-white">Projects</h2>
                <Row>
                    {projectsItems}
                </Row>
            </div>
        </Container>
    );
}

export default Bio;