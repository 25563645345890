import GoogleMapReact from 'google-map-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Map = ({location, zoomLevel, googleMapAPIKey}) => {
    if (!googleMapAPIKey || googleMapAPIKey.length === 0) {
        console.log("Map key not ready");
        return <p className="text-center">Map still loading (async), please wait...</p>;
    }

    return (
        <div className="map">
            <div className="google-map">
                <GoogleMapReact
                    bootstrapURLKeys={{key: googleMapAPIKey}}
                    defaultCenter={location}
                    defaultZoom={zoomLevel}
                >
                    <LocationPin
                        lat={location.lat}
                        lng={location.lng}
                        text={location.address}
                    />
                </GoogleMapReact>
            </div>
        </div>
    );
}

const LocationPin = ({text}) => (
    <div className="pin">
        <FontAwesomeIcon icon="map-marker" size="3x" className="pin-icon"/>
        <p className="pin-text">{text}</p>
    </div>
)

export default Map;