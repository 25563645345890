import React, {Component} from 'react'
import {Button} from 'reactstrap';
import Navigation from "./navigation";
import Typewriter from "typewriter-effect";

export default class Home extends Component {

    render() {
        const {name} = this.props;

        return (
            <div>
                <section>
                    <div id="home" className="background-main1">
                        {/*<div className="social-icons">*/}
                        {/*    <ul>*/}
                        {/*        <li ng-repeat="social in mainCtrl.socials">*/}
                        {/*            <a ng-href="{{social.url}}" target="_blank"*/}
                        {/*               ng-mouseenter="mainCtrl.selectSocial($index)"*/}
                        {/*               ng-mouseleave="mainCtrl.selectSocial()"*/}
                        {/*               ng-class="{'hover-social': mainCtrl.selectedSocial == $index}">*/}
                        {/*                <i className="fa fa-lg fa-fw {{social.name}}"></i>*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        <div className="welcome-main">
                            <div className="typewriter">
                                <Typewriter
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString("<span class='text-danger'>Kostis Tsaf</span>")
                                            .deleteAll()
                                            .typeString("<span class='text-white h2 lead' style='font-family: monospace;'>Konstantinos Tsafaris</span>")
                                            .pauseFor(500)
                                            .typeString("<div class='mb-4'></div>")
                                            .typeString("<span class='text-white lead' style='font-family: monospace;'>Web Developer</span>")
                                            .start();
                                    }}
                                />
                            </div>
                        </div>

                        {/*<md-button ng-if="mainCtrl.forceShowArrow || (showArrow)"*/}
                        {/*           ng-mouseenter="mainCtrl.forceShowArrow=true"*/}
                        {/*           ng-mouseleave="mainCtrl.forceShowArrow=false" id="scroll-top-button"*/}
                        {/*           className="md-fab md-mini md-accent"*/}
                        {/*           ng-click="mainCtrl.scrollToTop()" aria-label="Scroll to top">*/}
                        {/*    <i className="fa fa-chevron-up" aria-hidden="true"></i>*/}
                        {/*</md-button>*/}
                    </div>
                </section>
            </div>
        )
    }
}