import './css/App.css';
import './css/angular-css.css';
import './css/typewriter.css';
import './css/mine.css';
import './css/map.css';
import './css/contact-form.css';

import Navigation from "./components/navigation";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Home from './components/home';
import Bio from './components/bio';
import Skills from './components/skills';
import Projects from './components/projects';
import Contact from './components/contact';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faCoffee, faCode, faTerminal, faDatabase, faDesktop, faMapMarker, faLeaf } from '@fortawesome/free-solid-svg-icons';

library.add(fab, far, faCheckSquare, faCoffee, faCode, faTerminal, faDatabase, faDesktop, faMapMarker, faLeaf)

function App() {
    return (
        <Router>
            <div>
                <Navigation />

                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/home">
                        <Home />
                    </Route>
                    <Route path="/bio">
                        <Bio />
                    </Route>
                    <Route path="/skills">
                        <Skills />
                    </Route>
                    <Route path="/projects">
                        <Projects />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
