import {Col, Container, Row} from "reactstrap";
import MapSection from "./map";
import ContactForm from "./contactForm";
import {useEffect, useState} from "react";


function Contact() {
    const [googleMapAPI, setGoogleMapAPI] = useState({
        key: null
    });
    const [emailJS, setEmailJS] = useState({
        userId: null,
        templateId: null,
        serviceId: null
    });

    useEffect(() => {
        // Call our fetch function below once the component mounts
        fetch("/getGoogleMapDetails")
            .then(res => res.json())
            .then(data => {
                setGoogleMapAPI({key: data.googleMapKey});
            })
            .catch(error => console.error(error));

        fetch("/getEmailJS")
            .then(res => res.json())
            .then(data => {
                setEmailJS({
                    userId: data.emailJS.userId,
                    templateId: data.emailJS.templateId,
                    serviceId: data.emailJS.serviceId
                });
            })
            .catch(error => console.error(error));
    }, []); // <-- we can control for this effect to run only once during the lifetime of this component

    const location = {
        address: 'Thessaloniki, Greece',
        lat: 40.578396,
        lng: 23.021652
    }

    return (
        <Container fluid className="background-main5 d-flex w-100">
            <div className="w-100">
                <h2 className="text-center pt-4 pb-4 text-white">Contact</h2>
                {/*How many columns to fit in a row in each breakpoint size*/}
                <Row className="text-white">
                    <Col xs={12} md={8} className="order-last order-md-first">
                        <MapSection googleMapAPIKey={googleMapAPI.key}
                                    location={location} zoomLevel={6}/> {/* include it here */}
                    </Col>
                    <Col xs={12} md={4} className="order-first order-md-last">
                        <h3>Contact form</h3>
                        <ContactForm emailJS={emailJS}/>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default Contact;